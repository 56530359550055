import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby"
import styled from "styled-components";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import Sha1 from "../components/hash";
import axios from "axios";

const PrihlasitSeInner = styled.div`
  background: #FBFBFA;
  min-height: calc(100vh - 400px);
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 899px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #DFDFDF;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    padding: 40px;
    width: calc(100% - 50px - 80px);

    @media (max-width: 899px) {
      padding: 25px;
      width: calc(100% - 50px - 50px);
    }

    h1 {
      font-family: Ivar;
      font-size: 26px;
      color: #000000;
      margin-bottom: 25px;
    }

    label {
      display: block;
      margin-bottom: 30px;

      .errorMsg {
        font-family: Visuelt-Regular;
        line-height: 25px;
        font-size: 16px;
        color: #FF6060;
        margin: 0;
        margin-top: 8px;
      }

      span {
        display: block;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #6C6C6C;
        line-height: 25px;
        margin-bottom: 8px;
      }

      input[type=text] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=email] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=password] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        width: calc(100% - 24px);
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;

        &:focus {
          border: 2px solid #243A58;
        }
      }

      input[type=file] {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        max-width: calc(330px - 4px);
        line-height: 25px;

        @media (max-width: 900px) {
          width: calc(100% - 24px);
        }

        &:focus {
          border: 2px solid #243A58;
        }
      }

      textarea {
        background: #FFFFFF;
        border: 2px solid #D2D2D2;
        border-radius: 5px;
        padding: 10px;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000;
        line-height: 25px;
        width: calc(100% - 24px);
        resize: none;
        height: 190px;

        &:focus {
          border: 2px solid #243A58;
        }
      }
    }

    .inputError {
      input {
        border: 2px solid #FF6060 !important;
      }

      textarea {
        border: 2px solid #FF6060 !important;
      }

      select {
        border: 2px solid #FF6060 !important;
      }

      span {
        color: #FF6060;
      }

      .fakeCheckbox {
        border: 2px solid #FF6060 !important;
      }

      p {
        color: #FF6060;

        a {
          color: #FF6060 !important;
        }
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #585858;
      line-height: 25px;
      margin-bottom: 20px;

      a {
        text-decoration: underline;
        white-space: nowrap;
        color: #585858; 
      }
    }

    .errorMessage {
      background: #FF6060;
      color: #fff;
      font-family: Visuelt-Medium;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 25px;
    }

    .submit {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #243A58;
      max-width: 200px;
      width: 100%;
      border: 0;
      padding: 13px 0;
      margin-top: 10px;
      cursor: pointer;
      height: 50px;
      border-radius: 3px;

      &:hover {
        background: #1a2b42;
      }
    }
  }
`;

const PrihlasitSe = () => {
  const { register, handleSubmit, errors, control } = useForm(); //watch
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['loginSess']);


  const onSubmit = data => handleForm(data);

  const handleForm = ({email, password}) => {
    var hash = Sha1.hash(password, {});
    setIsLoading(true);

    // nastavení správné backend url

    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }

    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }

    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then(res => {
      const jwt = res.data.jwt;

      axios.get(`${backendUrl}/offers/${email}/${hash}`, {
        headers: {
          "Authorization": `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
      })
      .then(res => {

        if (res.status === 200) {
          if (res.data !== "") {
            setCookie("loginSess", res.data, { path: '/' });
            navigate("/nabidka/");
          }

          else {
            setErrorMsg("Špatný e-mail nebo heslo. Zkuste to prosím znovu");
          }
        }

        else {
          alert("Stala se chyba při přihlašování, zavolejte nám prosím na 315 558 136");
        }

        setIsLoading(false);
      });
    });
    
  }


  useEffect(() => {
    var jsmePrihlaseny = cookies.loginSess;

    if (jsmePrihlaseny) {
      if (jsmePrihlaseny !== "") {
        navigate("/nabidka/prehled/");
      }
    }
  }, []);





  return (
    <Layout>
      <SEO
        title="Příhlásit se | Moderní pohřební služba Goodbye.cz"
        description="Přihlášení pro zákazníky. Jste u nás poprvé? Pokud chcete nezávazně začít plánovat pohřeb, můžete tak zdarma učinit na naší stránce."
      />

      <PrihlasitSeInner>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Přihlášení pro zákazníky</h1>



          <label className={errors.email && "inputError"}>
            <span>E-mail</span>
            <input ref={register({ required: true })} type="email" name="email"/>
            {errors.email && <div className="errorMsg">Toto pole je povinné</div>}
          </label>

          <label className={errors.password && "inputError"}>
            <span>Heslo</span>
            <input ref={register({ required: true })} type="password" name="password"/>
            {errors.password && <div className="errorMsg">Toto pole je povinné</div>}
          </label>

          {errorMsg &&
            <p className="errorMessage">{errorMsg}</p>
          }

          <p>Máte nějaké problémy s přihlášením? Ozvěte se nám prosím na <a href="tel:00420315558136">315 558 136</a>.</p>
          <p>Jste u nás poprvé? Pokud chcete nezávazně začít plánovat pohřeb, můžete tak zdarma učinit <Link to="/nabidka/dotaznik/">zde</Link>.</p>

          <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Pokračovat"} />

        </form>

      </PrihlasitSeInner>
    </Layout>
  )
}

export default PrihlasitSe